import { HookType } from '@rossum/api-client/hooks';
import { LogLevel } from '@rossum/api-client/hooks';
import {
  Box,
  Stack,
  Tooltip,
  Typography,
  TypographyProps,
} from '@rossum/ui/material';
import { icons } from '../../../../containers/Extensions/lib/helpers';

type ColumnBaseProps = {
  value: string | JSX.Element;
  typoProps?: TypographyProps;
};

const ColumnBase = ({ value, typoProps }: ColumnBaseProps) => {
  const props: TypographyProps = {
    fontFamily: "'Roboto Mono Variable', monospace",
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    letterSpacing: 0,
    ...typoProps,
  };
  return (
    <Typography variant="body2" component="div" sx={{ ...props }}>
      {value}
    </Typography>
  );
};

type BasicColumnProps = {
  width: string;
  value: string | JSX.Element;
};

type LevelColumnProps = {
  width: string;
  level: LogLevel;
};

export const BasicColumn = ({ width, value }: BasicColumnProps) => (
  <Stack sx={{ width: t => `calc(${width} - ${t.spacing(2)})` }}>
    <ColumnBase value={value} />
  </Stack>
);

const levelColors: Record<LogLevel, TypographyProps['color']> = {
  ERROR: 'error.main',
  WARNING: 'warning.main',
  INFO: 'info.main',
};

export const LevelColumn = ({ width, level }: LevelColumnProps) => (
  <Stack width={width}>
    <ColumnBase
      value={level}
      typoProps={{
        fontWeight: '600',
        color: levelColors[level],
      }}
    />
  </Stack>
);

type IconColumnProps = {
  extensionType: HookType;
} & BasicColumnProps;

export const IconColumn = ({
  width,
  extensionType,
  value,
}: IconColumnProps) => {
  const Icon = icons[extensionType];

  return (
    <Stack
      sx={{ width: t => `calc(${width} - ${t.spacing(2)})` }}
      flexDirection="row"
      alignItems="center"
      gap={0.5}
    >
      <Stack sx={{ minWidth: 18 }}>
        <Icon size={18} />
      </Stack>
      <ColumnBase value={value} />
    </Stack>
  );
};

export const TooltipColumn = ({ width, value }: BasicColumnProps) => (
  <Stack width={width}>
    <Tooltip
      title={<Box sx={{ whiteSpace: 'pre-wrap' }}>{value}</Box>}
      placement="top-start"
    >
      <Box>
        <ColumnBase value={value} />
      </Box>
    </Tooltip>
  </Stack>
);
